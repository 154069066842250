import React, { useEffect, lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { initGA, logPageView } from './utils/analytics';

// Lazy loading pages for code-splitting
const SignupPage = lazy(() => import('./pages/SignupPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ForgotPage = lazy(() => import('./pages/ForgotPage'));
const ResetPage = lazy(() => import('./pages/ResetPage'));
const ResetSuccessPage = lazy(() => import('./pages/ResetSuccessPage'));
const LoaderPage = lazy(() => import('./pages/LoaderPage'));
const VerificationPage = lazy(() => import('./pages/VerificationPage'));
const VerifySuccessPage = lazy(() => import('./pages/VerifySuccessPage'));
const Home = lazy(() => import('./pages/Home'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const Discover = lazy(() => import('./pages/Discover'));
const EventDetails = lazy(() => import('./pages/EventDetails'));
const EventPage = lazy(() => import('./pages/EventPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const AdminEventPage = lazy(() => import('./pages/AdminEventPage')); 

// Layout Component with Memoization and Scroll Restoration
const Layout = React.memo(() => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
    window.scrollTo(0, 0); // Scroll to top on each route change
  }, [location]);

  return <Outlet />;
});

// Router Configuration
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Navigate to="/home" replace /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/forgot', element: <ForgotPage /> },
      { path: '/reset', element: <ResetPage /> },
      { path: '/reset1', element: <ResetSuccessPage /> },
      { path: '/loader', element: <LoaderPage /> },
      { path: '/verify', element: <VerificationPage /> },
      { path: '/verify1', element: <VerifySuccessPage /> },
      { path: '/privacy', element: <PrivacyPage /> },
      { path: '/terms-cond', element: <TermsPage /> },
      { path: '/home', element: <Home /> },
      { path: '/account', element: <AccountPage /> },
      { path: '/discover', element: <Discover /> },
      { path: '/discover/:categoryID', element: <Discover /> },
      {
        path: '/add-event',
        element: (
          <ProtectedRoute>
            <EventPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/admin', // Admin-specific add-event route
        element: (
          <ProtectedRoute>
            <AdminEventPage />
          </ProtectedRoute>
        ),
      },
      { path: '/event-details/:eventName', element: <EventDetails /> },
    ],
  },
]);

const App = () => {
  // Initialize Google Analytics once
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Suspense fallback={<LoaderPage />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
