import ReactGA from 'react-ga';

let isGAInitialized = false;
let initializePromise = null;

// Initialize Google Analytics and return a promise
export const initGA = () => {
  if (!initializePromise) {
    initializePromise = new Promise((resolve) => {
      const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
      console.log('Tracking ID used:', trackingId); // temporar y
      if (trackingId) {
        ReactGA.initialize(trackingId);
        isGAInitialized = true;
        console.log(`Google Analytics initialized with ID: ${trackingId}`);
        resolve(); // Resolve the promise when initialized
      } else {
        console.warn('Google Analytics tracking ID not found in environment variables');
        resolve(); // Resolve even if there's no tracking ID, to avoid blocking
      }
    });
  }
  return initializePromise; // Return the promise for other functions to wait on
};

// Log a page view after ensuring GA is initialized
export const logPageView = () => {
  if (isGAInitialized) {
    const path = window.location.pathname + window.location.search;
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
    console.log(`Page view logged for: ${path}`);
  } else {
    console.warn('Google Analytics not initialized. Page view not logged.');
  }
};
